export const topSlider = () => {
  $(function () {
    // mvのスライダー
    $("#js-mv-slider").on("init", function (event, slick) {
      const startIndex = 0; // 開始インデックス
      const endIndex = 3; // 終了インデックス

      $(".slick-slide:not(.slick-cloned)").each(function (index) {
        const slideIndex = $(this).attr("data-slick-index");
        if (slideIndex >= startIndex && slideIndex <= endIndex) {
          const html = $(this).find(".p-mv-content__thum-item").html();
          $("#js-mv-dots .slick-dots li").eq(index).html(html);
        }
        $(this).find(".p-mv-content__thum-item").remove();
      });
    });

    $("#js-mv-slider").slick({
      autoplay: false,
      dots: true,
      appendDots: $("#js-mv-dots"),
      arrows: false, // 矢印
      slidesToShow: 1,
      fade: true,
      speed: 3000,
      autoplaySpeed: 3000,
      autoplay: true,
      pauseOnFocus: false, //フォーカスで一時停止を無効
      pauseOnHover: false, //マウスホバーで一時停止を無効

      responsive: [
        {
          breakpoint: 768, // 768pxの画面幅以下の場合
          settings: {
            dots: true,
            appendDots: $("#js-mv-sp-dots"),
          },
        },
        // その他のブレークポイントに対しても設定を追加可能
      ],
    });

    // 導入事例
    // $("#js-case-slider").slick({
    //   autoplay: false,
    //   arrows: true, // 矢印
    //   appendArrows: $("#js-case-arrow"),
    //   slidesToShow: 2,
    // });

    // 表示枚数を反映させる
    $("#js-case-slider")
      .on("init", function (event, slick) {
        $("#js-case-arrow ").append(
          '<div class="slick-num"><span class="now-count"></span>/<span class="all-count"></span></div>'
        );
        $(".now-count").text(slick.currentSlide + 1); // 現在のスライド番号(+1が無いと0からスタートしてしまう)
        $(".all-count").text(slick.slideCount); // スライドの総数
      })
      .slick({
        autoplay: false,
        arrows: true, // 矢印
        appendArrows: $("#js-case-arrow"),
        slidesToShow: 2,
        // speed: 3000,
        // autoplaySpeed: 3000,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1025, // 768pxの画面幅以下の場合
            settings: {
              slidesToShow: 1, // 1枚表示に変更
            },
          },
          // その他のブレークポイントに対しても設定を追加可能
        ],
      })
      .on("beforeChange", function (event, slick, currentSlide, nextSlide) {
        $(".now-count").text(nextSlide + 1); // 現在のスライド番号の次のスライドになったら番号を+1
      });

    // // personのスライダー
    // // スライダーの数
    // let i = 4;
    // $(".p-person-content__slider-list").slick({
    //   appendArrows: $(".p-person-content__slider-arrow"),
    //   centerMode: true,
    //   centerPadding: "20px",
    //   slidesToShow: i,
    //   // autoplay: true,
    //   responsive: [
    //     {
    //       breakpoint: 768, // ブレークポイントの幅
    //       settings: {
    //         centerPadding: "40px",
    //         slidesToShow: 1,
    //       },
    //     },
    //   ],
    // });

    // const $slider = $(".p-person-content__slider-list");
    // $slider.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
    //   $slider.find(".slick-slide").each((index, el) => {
    //     const $this = $(el),
    //       slickindex = $this.attr("data-slick-index");
    //     if (nextSlide == slick.slideCount - 1 && currentSlide == 0) {
    //       if (slickindex == "-1") {
    //         $this.addClass("is-active-next");
    //         // $this.next().addClass("is-active-next");
    //         $this.prev().addClass("is-active-next");
    //       } else {
    //         $this.removeClass("is-active-next");
    //       }
    //     } else if (nextSlide == 0) {
    //       if (slickindex == slick.slideCount) {
    //         $this.addClass("is-active-next");
    //         $this.prev().addClass("is-active-next");
    //         $this.nextAll().slice(0, 2).addClass("is-active-next");
    //       } else {
    //         $this.removeClass("is-active-next");
    //       }
    //     } else {
    //       $this.removeClass("is-active-next");
    //     }
    //   });
    // });

    // // galleryのスライダー
    // // スライダーの数
    // $(".p-gallery-content__content-list").slick({
    //   appendArrows: $(".p-gallery-content__slider-arrow"),
    //   centerMode: true,
    //   slidesToShow: 1,
    //   centerPadding: "22.8%",
    //   // autoplay: true,
    //   responsive: [
    //     {
    //       breakpoint: 768, // ブレークポイントの幅
    //       settings: {
    //         centerPadding: "20px",
    //       },
    //     },
    //   ],
    // });
  });
};
