export const aboutData = () => {
    $(function () {
        // window.odometerOptions = {
        //     format: '(.ddd)',
        // };
        $('.js-counterup').on('inview', function () {
            const _ = $(this);
            if (!_.hasClass('runned')) {
                const num = _.attr('data-num');
                _.html(num);
                _.addClass('runned');
            }
        });
        $('.js-chart01').on('inview', function () {
            const _ = $(this);
            if (!_.hasClass('runned')) {
                _.addClass('runned');
            }
        });
        $(window).on('load scroll', function () {
            const { scrollY, innerHeight } = window;
            const data_item_chart_2 = $('.js-chart02');

            const data_item_chart_3 = $('.js-chart03');
            if(data_item_chart_2.length){
                const top2 = data_item_chart_2.offset().top;  
                if (scrollY + innerHeight * 0.85 > top2 && !data_item_chart_2.hasClass('runned')) {
                    $('.p-sdata-company-chart02__items').each(function () {
                        let data = [];
                        let color = [];
                        $(this)
                            .find('.num')
                            .each(function () {
                                data.push($(this).data('num'));
                                color.push($(this).data('color'));
                            });
    
                        var ctx = $(this).find('.chart');
                        var myChart = new Chart(ctx, {
                            type: 'pie',
                            data: {
                                datasets: [
                                    {
                                        data: data,
                                        backgroundColor: color,
                                        borderWidth: 3,
                                    },
                                ],
                            },
    
                            options: {
                                animation: {
                                    // duration: 0 // disable animate
                                },
    
                                responsive: true,
                                maintainAspectRatio: false, //Fix break layout when using version 2
                                events: [], //  disable hover background
                                plugins: {
                                    tooltip: {
                                        enabled: false, //   disable tooltips
                                    },
                                },
                            },
                        });
                    });
                    data_item_chart_2.addClass('runned');
                }
            }
            if(data_item_chart_3.length){
                const top3 = data_item_chart_3.offset().top;
                if (
                    scrollY + innerHeight * 0.85 > top3 &&
                    !data_item_chart_3.hasClass('runned')
                ) {
                    $('.p-sdata-employees-chart03').each(function () {
                        let data = [];
                        let color = [];
                        $(this)
                            .find('.num-data')
                            .each(function () {
                                data.push($(this).data('num'));
                                color.push($(this).data('color'));
                            });
    
                        var ctx = $(this).find('.chart');
                        var myChart = new Chart(ctx, {
                            type: 'doughnut',
                            data: {
                                datasets: [
                                    {
                                        data: data,
                                        backgroundColor: color,
                                        borderWidth: 3,
                                    },
                                ],
                            },
    
                            options: {
                                animation: {
                                    // duration: 0 // disable animate
                                },
                                cutoutPercentage: 60,
                                responsive: true,
                                maintainAspectRatio: false, //Fix break layout when using version 2
                                events: [], //  disable hover background
                                plugins: {
                                    tooltip: {
                                        enabled: false, //   disable tooltips
                                    },
                                },
                            },
                        });
                    });
                    data_item_chart_3.addClass('runned');
                }
            }
        });
    });
};
