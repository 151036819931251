export const tabBtn = () => {
  $(function () {
    const body = $("body");

    const $jsTabBtn = $(".js-tab-btn");

    const $jsTabTarget = $(".js-tab-target");

    console.log("move");

    $jsTabBtn.on("click", function () {
      $jsTabBtn.removeClass("is-active");
      let n = $(this).attr("data-no");
      $(this).addClass("is-active");

      $jsTabTarget.each(function () {
        $(this).removeClass("is-show");
        if ($(this).attr("data-no") == n) {
          $(this).addClass("is-show");
        }
      });
    });
  });
};
