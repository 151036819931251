import { inview } from "./assets/js/modules/inview.js";
import { hambergerToggle } from "./assets/js/modules/hamburgertoggle.js";
import { topSlider } from "./assets/js/modules/top-slider.js";
import { qandaMark } from "./assets/js/modules/qanda-mark.js";
import { pageTopBtnAction } from "./assets/js/modules/pagetopbtn-action.js";
import { accordion } from "./assets/js/modules/accordion.js";
import { initPcNav } from "./assets/js/modules/pcNav.js";
import { spNavDropToggle } from "./assets/js/modules/spDropToggle.js";
import { resizeAction } from "./assets/js/modules/resize-action.js";
import { aboutData } from "./assets/js/modules/about-data.js";
import { tabBtn } from "./assets/js/modules/tab-btn.js";
import { video } from "./assets/js/modules/video.js";
import { cookie } from "./assets/js/modules/cookie.js";
import { loadingAni } from "./assets/js/modules/loadding-ani.js";

const $body = $("body");
spNavDropToggle();
initPcNav();
inview();
hambergerToggle();
pageTopBtnAction();
topSlider();
qandaMark();
accordion();
// aboutData();
video();
cookie();
loadingAni();
if ($body.hasClass("top")) {
  resizeAction();
}

if ($body.hasClass("data")) {
  aboutData();
}

if ($body.hasClass("case")) {
  tabBtn();
}
