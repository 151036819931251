
export const video = () => {
    $(function () {
       if($('.c-video__btn').length){
        $('.c-video__btn').each(function(){
            $(this).click(function(){
                $(this).hide();
                $(this).parent().find('.c-video__bg').hide();
                $(this).parent().find('video').get(0).play();
            });
        });
        $('.c-video video').on('pause', function(){
            $(this).parent().find('.c-video__bg').show();
            $(this).parent().find('.c-video__btn').show();
            $(this).parent().find('video').get(0).pause();
        });
       }
    });
  };
  